import React, { useEffect, useContext } from "react";

import GlobalContext from "../../context/GlobalContext";

const headerConfigDefault = {
  theme: "light",
  variant: "primary",
  align: "left",
  isFluid: false,
  button: "cta", // trial, cart, cta, account, null
  buttonText: "Schedule a Demo", // trial, cart, cta, account, null
  buttonAction: "/#RetailerContact"
};

const footerConfigDefault = {
  theme: "info",
  style: "style2" //style1, style2, style3
};

const PageWrapper = ({
  children,
  headerConfig = null,
  footerConfig = null
}) => {
  /* eslint-disable */
  const gContext = useContext(GlobalContext);

  useEffect(() => {
    if (gContext.themeDark) {
      gContext.setHeader({
        ...headerConfigDefault,
        ...headerConfig,
        theme: "info"
      });
      gContext.setFooter({
        ...footerConfigDefault,
        ...footerConfig,
        theme: "dark"
      });
    } else {
      gContext.setHeader({ ...headerConfigDefault, ...headerConfig });
      gContext.setFooter({ ...footerConfigDefault, ...footerConfig });
    }
  }, [gContext.themeDark, headerConfig, footerConfig]);
  /* eslint-enable */
  return <>{children}</>;
};

export default PageWrapper;
